( () => {
    const menuItems = document.querySelectorAll('.main-menu--item');
    menuItems.forEach( el => {
        el.addEventListener('click', evt => {
            if ( window.location.pathname != '/' ) return true;
            evt.preventDefault();
            document.querySelector( evt.target.hash ).scrollIntoView({ behavior: 'smooth' });
        })
    });

    const contact = document.querySelector('.contact-cta');
    window.addEventListener( 'scroll', () => {
        window.scrollY > 300 ? contact.classList.add('show') : contact.classList.remove('show');
    }, { passive: true } );

    const yearSpan = document.querySelector('[data-year]');
    if ( !! yearSpan ) {
        const date = new Date().getFullYear();
        yearSpan.innerText = date;
    }
})();